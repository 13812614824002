import { formatCounter } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { chatsGetUnreadMessagesCount } from '@selectors/chats';
import { invitationsGetPendingInvitationsCount } from '@selectors/invitations';
import { supportGetUnreadMessagesCount } from '@selectors/support';
import { viewsGetPendingCount } from '@selectors/views';
import { useTypedSelector } from '@hooks/store';
import { Bottombar } from '@kit/bottombar';
import { EmailIconStyled, InvitationIconStyled, SearchIconStyled, ViewsIconStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BottombarWidget: AppFC = () => {
  const unreadMessagesCount = useTypedSelector(state => chatsGetUnreadMessagesCount(state) + (supportGetUnreadMessagesCount(state) ?? 0));
  const pendingInvitationsCount = useTypedSelector(invitationsGetPendingInvitationsCount);
  const viewsCount = useTypedSelector(viewsGetPendingCount);
  return _jsx(Bottombar, {
    items: [{
      routeDescriptor: ROUTES.search.index.getDescriptor(),
      icon: _jsx(SearchIconStyled, {}),
      checkActive: true
    }, {
      routeDescriptor: ROUTES.chats.index.getDescriptor(),
      badgeText: formatCounter(unreadMessagesCount),
      icon: _jsx(EmailIconStyled, {}),
      checkActive: true
    }, {
      routeDescriptor: ROUTES.invitations.index.getDescriptor(),
      badgeText: formatCounter(pendingInvitationsCount),
      icon: _jsx(InvitationIconStyled, {}),
      checkActive: true
    }, {
      routeDescriptor: ROUTES.views.index.getDescriptor(),
      badgeText: formatCounter(viewsCount),
      icon: _jsx(ViewsIconStyled, {}),
      checkActive: true
    }]
  });
};