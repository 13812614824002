import { AppFCC } from '@types';
import { PointStyled, WithPointStyled } from './styled';
import { WithPointProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const WithPoint: AppFCC<WithPointProps> = props => {
  const {
    className,
    pointClassName,
    children,
    color,
    showPoint
  } = props;
  return _jsxs(WithPointStyled, {
    className: className,
    children: [children, !showPoint ? null : _jsx(PointStyled //
    , {
      className: pointClassName,
      color: color
    })]
  });
};