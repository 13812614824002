import { AppFCC } from '@types';
import { HeaderAuthLinkStyled, HeaderContentStyled, HeaderControlsWalletStyled, HeaderLinkIconStyled, HeaderLinkTextStyled, HeaderLogoLinkStyled, HeaderLogoStyled } from './styled';
import { HeaderContentDesktopProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderContentDesktop: AppFCC<HeaderContentDesktopProps> = props => {
  const {
    logoLinkRouteDescriptor,
    authLinkProps,
    headerControls,
    headerWallet,
    headerMenu,
    showHeaderControlsWallet,
    domain
  } = props;
  return _jsxs(HeaderContentStyled, {
    children: [_jsx(HeaderLogoLinkStyled, {
      routeDescriptor: logoLinkRouteDescriptor,
      children: _jsx(HeaderLogoStyled //
      , {
        src: "/images/logo-header.svg",
        alt: domain,
        loaderDisabled: true
      })
    }), !showHeaderControlsWallet ? null : _jsxs(HeaderControlsWalletStyled, {
      children: [headerControls, headerWallet]
    }), headerMenu, _jsxs(HeaderAuthLinkStyled //
    , {
      className: "group",
      onClick: authLinkProps.onClick,
      children: [_jsx(HeaderLinkIconStyled, {
        children: authLinkProps.icon
      }), _jsx(HeaderLinkTextStyled, {
        suppressHydrationWarning: true,
        children: authLinkProps.text
      })]
    })]
  });
};