import { useMemo } from 'react';
import { getCurrentYear } from '@helpers/date';
import { defineRoleTypeByRoute } from '@helpers/member';
import { calculateUserAgreementUrl } from '@helpers/misc';
import { getOriginDomain, getSupportEmail } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { accountGetIsAuthorized } from '@selectors/account';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { useNavigator } from '@hooks/routing';
import { useTypedSelector } from '@hooks/store';
import { TERM_FOR_EU_RESIDENT } from '@scenes/terms-for-eu-residents';
import { Footer } from '@kit/footer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FooterWidget: AppFC = () => {
  const domain = useMemo(() => getOriginDomain(), []);
  const currentYear = useMemo(() => getCurrentYear(), []);
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const navigator = useNavigator();
  const pathname = navigator.getPathname();
  const match = navigator.findMatch(pathname);
  const processedRoleType = useMemo(() => {
    if (isAuthorized) {
      return roleType;
    }
    return defineRoleTypeByRoute(match?.route);
  }, [isAuthorized, match?.route, roleType]);
  const logoLinkRouteDescriptor = useMemo(() => ROUTES.search.index.getDescriptor(), []);
  const userAgreementLinkProps = useMemo(() => ({
    href: calculateUserAgreementUrl(processedRoleType),
    children: 'Пользовательское соглашение'
  }), [processedRoleType]);
  const privacyPolicyLinkProps = useMemo(() => ({
    href: ROUTES.privacyPolicy.index.getUrl(),
    children: 'Политика конфиденциальности'
  }), []);
  const tariffsLinkProps = useMemo(() => ({
    routeDescriptor: ROUTES.tariffs.index.getDescriptor(),
    children: 'Тарифы'
  }), []);
  const termsForEuResidentsLinkProps = useMemo(() => ({
    href: ROUTES.termsForEuResidents.index.getUrl(),
    children: 'T&C for EU Residents'
  }), []);
  const rightOfWithdrawalLinkProps = useMemo(() => ({
    href: ROUTES.rightOfWithdrawalForEuResidents.index.getUrl(),
    children: 'Right of Withdrawal for EU Residents'
  }), []);
  const notificationOfIllegalityLinkProps = useMemo(() => ({
    href: `${ROUTES.termsForEuResidents.index.getUrl()}#${TERM_FOR_EU_RESIDENT.NOTIFICATION_OF_ILLEGALITY}`,
    children: 'Notification of illegality'
  }), []);
  const supportEmail = useMemo(() => getSupportEmail(), []);
  const emailLinkProps = useMemo(() => ({
    href: `mailto:${supportEmail}`,
    children: `${supportEmail}`
  }), [supportEmail]);
  const contactsLinkProps = useMemo(() => ({
    routeDescriptor: ROUTES.contacts.index.getDescriptor(),
    children: `Контакты`
  }), []);
  const copyrightLinkProps = useMemo(() => ({
    routeDescriptor: ROUTES.index.getDescriptor(),
    copyright: String(currentYear)
  }), [currentYear]);
  return _jsx(Footer, {
    logoLinkRouteDescriptor: logoLinkRouteDescriptor,
    userAgreementLinkProps: userAgreementLinkProps,
    privacyPolicyLinkProps: privacyPolicyLinkProps,
    tariffsLinkProps: tariffsLinkProps,
    emailLinkProps: emailLinkProps,
    contactsLinkProps: contactsLinkProps,
    copyrightLinkProps: copyrightLinkProps,
    termsForEuResidentsLinkProps: termsForEuResidentsLinkProps,
    rightOfWithdrawalLinkProps: rightOfWithdrawalLinkProps,
    notificationOfIllegalityLinkProps: notificationOfIllegalityLinkProps,
    domain: domain
  });
};