import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { NAVIGATION_MODE, QUERY_PARAM } from '@config/routing';
import { getOriginDomain } from '@helpers/url';
import { AuthLabels } from '@localization/translations';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { accountGetIsAuthorized } from '@selectors/account';
import { sidebarGetHidden } from '@selectors/sidebar';
import { useNavigator } from '@hooks/routing';
import { useTypedSelector } from '@hooks/store';
import { HeaderLinksWidget } from '@widgets/header/header-links';
import { HeaderMenuWidget } from '@widgets/header/header-menu';
import { HeaderWalletWidget } from '@widgets/header/header-wallet';
import { HeaderContentDesktop } from '@kit/header/header-content/desktop';
import { ExitIconStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderContentDesktopWidget: AppFC = () => {
  const navigator = useNavigator();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const sidebarIsHidden = useTypedSelector(sidebarGetHidden);
  const redirect = useCallback(async () => {
    if (isAuthorized) {
      await navigator.navigate(ROUTES.account.logoff.getDescriptor(undefined,
      //
      {
        mode: NAVIGATION_MODE.REPLACE
      }));
    } else {
      await navigator.navigateQuery({
        [QUERY_PARAM.SOURCE]: POPUP.ACCOUNT_LOGIN_MODAL
      }, {
        mode: NAVIGATION_MODE.REPLACE
      });
    }
  }, [isAuthorized, navigator]);
  const authLinkProps = useMemo(() => ({
    onClick: redirect,
    icon: _jsx(ExitIconStyled, {}),
    text: isAuthorized //
    ? AuthLabels.SIGN_OUT : AuthLabels.SIGN_IN
  }), [isAuthorized, redirect]);
  const {
    headerControls,
    headerWallet
  } = useMemo(() => {
    if (!isAuthorized) {
      return {};
    }
    return {
      headerControls: _jsx(HeaderLinksWidget, {}),
      headerWallet: _jsx(HeaderWalletWidget, {})
    };
  }, [isAuthorized]);
  const logoLinkRouteDescriptor = useMemo(() => ROUTES.search.index.getDescriptor(), []);
  return _jsx(HeaderContentDesktop, {
    logoLinkRouteDescriptor: logoLinkRouteDescriptor,
    authLinkProps: authLinkProps,
    headerControls: headerControls,
    headerWallet: headerWallet,
    headerMenu: _jsx(HeaderMenuWidget, {}),
    showHeaderControlsWallet: sidebarIsHidden,
    domain: getOriginDomain()
  });
};