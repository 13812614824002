import { useMemo, useRef, useState } from 'react';
import { isDesktop } from '@helpers/geometry';
import { AppFC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { useIntersectionObserver, UseIntersectionObserverArgs } from '@hooks/intersection-observer';
import { useScrollDelta, UseScrollDeltaArgs } from '@hooks/scroll-delta';
import { useTypedSelector } from '@hooks/store';
import { PreventSSR } from '@managers/seo/prevent-ssr';
import { SidebarWidget } from '@widgets/sidebar';
import { LayoutSideWidgetStyled } from './styled';
import { LayoutSideWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LayoutSideWidget: AppFC<LayoutSideWidgetProps> = props => {
  const {
    footerElement
  } = props;
  const geometryType = useTypedSelector(geometryGetType);
  const _isDesktop = isDesktop(geometryType);
  const footerIsVisible = useRef(false);
  const [isSticky, setIsSticky] = useState(false);
  const useIntersectionObserverArgs: UseIntersectionObserverArgs = useMemo(() => ({
    target: footerElement,
    callback: entries => {
      if (!_isDesktop) {
        return;
      }
      footerIsVisible.current = entries //
      .some(entry => entry.isIntersecting);
    },
    options: {
      threshold: 0.05
    }
  }), [_isDesktop, footerElement]);
  useIntersectionObserver(useIntersectionObserverArgs);
  const useScrollDeltaArgs: UseScrollDeltaArgs = useMemo(() => ({
    onScrollDown: () => {
      if (!_isDesktop) {
        return;
      }
      if (footerIsVisible.current) {
        setIsSticky(true);
      }
    },
    onScrollUp: (_delta, scrollTop) => {
      if (!_isDesktop) {
        return;
      }
      if (!footerIsVisible.current || !scrollTop) {
        setIsSticky(false);
      }
    }
  }), [_isDesktop]);
  useScrollDelta(useScrollDeltaArgs);
  return _jsx(LayoutSideWidgetStyled, {
    isSticky: _isDesktop && isSticky,
    children: _jsx(PreventSSR, {
      children: _jsx(SidebarWidget, {})
    })
  });
};