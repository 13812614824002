import { useMemo, useRef, useState } from 'react';
import { AppFCC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { sidebarGetHidden } from '@selectors/sidebar';
import { useTypedSelector } from '@hooks/store';
import { ErrorBoundary } from '@managers/logging/error-boundary';
import { BottombarWidget } from '@widgets/bottombar';
import { LayoutFooterWidget } from './layout-footer';
import { LayoutHeaderWidget } from './layout-header';
import { LayoutSideWidget } from './layout-side';
import { LayoutWidgetBottombarStyled, LayoutWidgetContentContainerStyled, LayoutWidgetContentMainStyled, LayoutWidgetContentSideStyled, LayoutWidgetContentStyled, LayoutWidgetStyled } from './styled';
import { LayoutWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LayoutWidget: AppFCC<LayoutWidgetProps> = props => {
  const {
    children,
    headerIsHiddenOn,
    sidebarIsHiddenOn,
    bottombarIsHiddenOn,
    footerIsHiddenOn
  } = props;
  const headerRef = useRef<HTMLDivElement>(null);
  const [footerElement, setFooterElement] = useState<null | HTMLDivElement>();
  const sidebarIsHiddenState = useTypedSelector(sidebarGetHidden);
  const geometryType = useTypedSelector(geometryGetType);
  const headerIsHidden = useMemo(() => headerIsHiddenOn?.includes(geometryType), [geometryType, headerIsHiddenOn]);
  const sidebarIsHidden = useMemo(() => sidebarIsHiddenOn?.includes(geometryType), [geometryType, sidebarIsHiddenOn]);
  const bottombarIsHidden = useMemo(() => bottombarIsHiddenOn?.includes(geometryType), [geometryType, bottombarIsHiddenOn]);
  const footerIsHidden = useMemo(() => footerIsHiddenOn?.includes(geometryType), [geometryType, footerIsHiddenOn]);
  const _sidebarIsHidden = sidebarIsHidden || sidebarIsHiddenState;
  return _jsxs(LayoutWidgetStyled, {
    headerIsHidden: headerIsHidden,
    bottombarIsHidden: bottombarIsHidden,
    children: [headerIsHidden ? null : _jsx(LayoutHeaderWidget, {
      headerRef: headerRef,
      footerElement: footerElement
    }), _jsx(LayoutWidgetContentStyled, {
      children: _jsxs(LayoutWidgetContentContainerStyled, {
        sidebarIsHidden: _sidebarIsHidden,
        children: [_jsx(LayoutWidgetContentSideStyled, {
          children: _sidebarIsHidden ? null : _jsx(LayoutSideWidget, {
            footerElement: footerElement
          })
        }), _jsx(LayoutWidgetContentMainStyled, {
          children: _jsx(ErrorBoundary, {
            children: children
          })
        })]
      })
    }), footerIsHidden ? null : _jsx(LayoutFooterWidget, {
      footerRef: setFooterElement
    }), bottombarIsHidden ? null : _jsx(LayoutWidgetBottombarStyled, {
      children: _jsx(BottombarWidget, {})
    })]
  });
};