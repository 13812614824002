import dynamic from 'next/dynamic';
import { isServer } from '@helpers/misc';
import { AppFCC } from '@types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DynamicWrapper = dynamic(() => import('./dynamic-wrapper').then(module => module.DynamicWrapper), {
  ssr: false
});
export const PreventSSR: AppFCC = props => {
  const {
    children
  } = props;
  return _jsx(DynamicWrapper, {
    children: isServer() ? null : children
  });
};