import { useMemo, useRef, useState } from 'react';
import { isDesktop } from '@helpers/geometry';
import { AppFCC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { useDidMount } from '@hooks/did-mount';
import { useIntersectionObserver, UseIntersectionObserverArgs } from '@hooks/intersection-observer';
import { useScrollDelta, UseScrollDeltaArgs } from '@hooks/scroll-delta';
import { useTypedSelector } from '@hooks/store';
import { HeaderWidget } from '@widgets/header';
import { Container } from '@kit/container';
import { LayoutHeaderWidgetStyled } from './styled';
import { LayoutHeaderWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LayoutHeaderWidget: AppFCC<LayoutHeaderWidgetProps> = props => {
  const {
    headerRef,
    footerElement
  } = props;
  const didMount = useDidMount();
  const geometryType = useTypedSelector(geometryGetType);
  const _isDesktop = isDesktop(geometryType);
  const footerIsVisible = useRef(false);
  const [isHidden, setIsHidden] = useState(false);
  const useIntersectionObserverArgs: UseIntersectionObserverArgs = useMemo(() => ({
    target: footerElement,
    callback: entries => {
      if (!_isDesktop) {
        return;
      }
      footerIsVisible.current = entries //
      .some(entry => entry.isIntersecting);
    },
    options: {
      threshold: 0.05
    }
  }), [_isDesktop, footerElement]);
  useIntersectionObserver(useIntersectionObserverArgs);
  const useScrollDeltaArgs: UseScrollDeltaArgs = useMemo(() => ({
    onScrollDown: () => {
      if (!_isDesktop) {
        return;
      }
      if (footerIsVisible.current) {
        setIsHidden(true);
      }
    },
    onScrollUp: (_delta, scrollTop) => {
      if (!_isDesktop) {
        return;
      }
      if (!footerIsVisible.current || !scrollTop) {
        setIsHidden(false);
      }
    }
  }), [_isDesktop]);
  useScrollDelta(useScrollDeltaArgs);
  if (!didMount) {
    return null;
  }
  return _jsx(LayoutHeaderWidgetStyled //
  , {
    ref: headerRef,
    isHidden: _isDesktop && isHidden,
    children: _jsx(Container, {
      children: _jsx(HeaderWidget, {})
    })
  });
};