import { AppFC } from '@types';
import { HeaderLinksInvitationsWidget } from './header-links-invitations';
import { HeaderLinksViewsWidget } from './header-links-views';
import { headerLinksWidgetLinkClassName, HeaderLinksWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderLinksWidget: AppFC = () => {
  return _jsxs(HeaderLinksWidgetStyled, {
    children: [_jsx(HeaderLinksInvitationsWidget, {
      className: headerLinksWidgetLinkClassName
    }), _jsx(HeaderLinksViewsWidget, {
      className: headerLinksWidgetLinkClassName
    })]
  });
};