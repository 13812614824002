import { AppFCC } from '@types';
import { Link, LinkContext, LinkProps } from '@managers/links/link';
import { BADGE_COLOR } from '@kit/badge';
import { WithBadge } from '@kit/with-badge';
import { BottombarItemsStyled, BottombarItemStyled, BottombarLinkContentStyled, BottombarStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type BottombarItem = {
  icon: React.ReactElement;
  badgeText?: string;
  routeDescriptor: LinkProps['routeDescriptor'];
  checkActive?: boolean;
};
type BottombarItems = BottombarItem[];
type BottombarProps = {
  items: BottombarItems;
};
export const Bottombar: AppFCC<BottombarProps> = props => {
  const {
    items
  } = props;
  return _jsx(BottombarStyled, {
    children: _jsx(BottombarItemsStyled, {
      children: items.map((item, index) => {
        const {
          icon,
          badgeText,
          routeDescriptor,
          checkActive
        } = item;
        return _jsx(BottombarItemStyled, {
          children: _jsx(Link //
          , {
            routeDescriptor: routeDescriptor,
            checkActive: checkActive,
            children: _jsx(LinkContext.Consumer, {
              children: ({
                isActive
              }) => {
                return _jsx(BottombarLinkContentStyled, {
                  isActive: isActive,
                  children: _jsx(WithBadge //
                  , {
                    color: BADGE_COLOR.RED,
                    text: badgeText,
                    children: icon
                  })
                });
              }
            })
          })
        }, index);
      })
    })
  });
};