import { AppFCC } from '@types';
import { BADGE_COLOR } from '@kit/badge';
import { ButtonOnClick } from '@kit/buttons/button';
import { WithBadge } from '@kit/with-badge';
import { HeaderButtonStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type HeaderButtonProps = {
  className?: string;
  badgeText?: string;
  onClick?: ButtonOnClick;
};
export const HeaderButton: AppFCC<HeaderButtonProps> = props => {
  const {
    badgeText,
    children,
    onClick,
    className
  } = props;
  return _jsx(HeaderButtonStyled //
  , {
    className: className,
    onClick: onClick,
    children: _jsx(WithBadge //
    , {
      color: BADGE_COLOR.RED,
      text: badgeText,
      children: children
    })
  });
};