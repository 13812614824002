import { useEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { addEvent } from '@helpers/dom';
import { throttle } from '@helpers/misc';
import { UseScrollDelta } from './types';
const THROTTLE_INTERVAL = 50;
export const useScrollDelta: UseScrollDelta = args => {
  const {
    target: originTarget,
    onScrollDown,
    onScrollUp,
    throttleInterval = THROTTLE_INTERVAL
  } = args;
  useEffect(() => {
    const target = originTarget ?? document.documentElement;
    let lastScrollTop = target.scrollTop;
    const throttledScrollHandler = throttle((event: MouseEvent) => {
      const scrollTop = target.scrollTop;
      if (scrollTop > lastScrollTop) {
        onScrollDown?.(scrollTop - lastScrollTop, scrollTop, event);
      }
      if (scrollTop < lastScrollTop) {
        onScrollUp?.(lastScrollTop - scrollTop, scrollTop, event);
      }
      lastScrollTop = scrollTop;
    }, throttleInterval, {
      trailing: true
    });
    return addEvent<MouseEvent>(originTarget ?? window, EVENT_NAMES.SCROLL, throttledScrollHandler);
  }, [onScrollDown, onScrollUp, originTarget, throttleInterval]);
};