export const LAYOUT_HEADER_HEIGHT = {
  mobile: '3rem',
  tablet: '5rem',
  desktop: '5.5rem'
};
export const LAYOUT_BOTTOMBAR_HEIGHT = {
  mobile: '2.75rem',
  tablet: '4.5rem'
};
export const LAYOUT_CONTENT_CONTAINER_PADDING = {
  mobile: {
    left: '1rem',
    right: '1rem',
    top: '1rem',
    bottom: '2.25rem'
  },
  tablet: {
    left: '1.5rem',
    right: '1.5rem',
    top: '1.5rem',
    bottom: '2.75rem'
  },
  desktop: {
    left: '2.75rem',
    right: '2.75rem',
    top: '3.25rem',
    bottom: '3.25rem'
  }
};