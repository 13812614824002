import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { invitationsGetPendingInvitationsCount } from '@selectors/invitations';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { AccessControlLink } from '@managers/links/access-control-link';
import { POINT_COLOR, WithPoint } from '@kit/with-point';
import { HeaderLinksInvitationsWidgetIconStyled } from './styled';
import { HeaderLinksInvitationsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderLinksInvitationsWidget: AppFCC<HeaderLinksInvitationsWidgetProps> = props => {
  const {
    className
  } = props;
  const invitationsCount = useTypedSelector(invitationsGetPendingInvitationsCount);
  const routeDescriptor = useMemo(() => ROUTES.invitations.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(routeDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(routeDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile]);
  return _jsx(AccessControlLink, {
    className: className,
    routeDescriptor: routeDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    children: _jsx(WithPoint //
    , {
      color: POINT_COLOR.RED,
      showPoint: invitationsCount > 0,
      children: _jsx(HeaderLinksInvitationsWidgetIconStyled, {})
    })
  });
};