import { AppFCC } from '@types';
import { WalletIcon } from '@icons';
import { HeaderWalletAmountStyled, HeaderWalletBalanceStyled, HeaderWalletButtonStyled, HeaderWalletCurrencyStyled, HeaderWalletIconWrapperStyled, HeaderWalletInfoStyled, HeaderWalletStyled } from './styled';
import { HeaderWalletProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderWallet: AppFCC<HeaderWalletProps> = props => {
  const {
    amount,
    currency,
    actionButtonProps
  } = props;
  const {
    icon,
    disabled,
    onClick
  } = actionButtonProps ?? {};
  return _jsxs(HeaderWalletStyled, {
    children: [_jsxs(HeaderWalletInfoStyled, {
      children: [_jsx(HeaderWalletIconWrapperStyled, {
        children: _jsx(WalletIcon, {})
      }), _jsxs(HeaderWalletBalanceStyled, {
        children: [_jsx(HeaderWalletAmountStyled, {
          children: amount
        }), _jsx(HeaderWalletCurrencyStyled, {
          children: currency
        })]
      })]
    }), _jsx(HeaderWalletButtonStyled //
    , {
      disabled: disabled,
      onClick: onClick,
      children: icon
    })]
  });
};