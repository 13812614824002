import { AppFCC } from '@types';
import { useDidMount } from '@hooks/did-mount';
import { FooterWidget } from '@widgets/footer';
import { Container } from '@kit/container';
import { LayoutWidgetFooterStyled } from './styled';
import { LayoutFooterWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LayoutFooterWidget: AppFCC<LayoutFooterWidgetProps> = props => {
  const {
    footerRef
  } = props;
  const didMount = useDidMount();
  if (!didMount) {
    return null;
  }
  return _jsx(LayoutWidgetFooterStyled, {
    ref: footerRef,
    children: _jsx(Container, {
      children: _jsx(FooterWidget, {})
    })
  });
};