import { RootState } from '@store';
import { chatsGetUnreadMessagesCount } from '@selectors/chats';
import { invitationsGetPendingInvitationsCount } from '@selectors/invitations';
import { supportGetUnreadMessagesCount } from '@selectors/support';
import { viewsGetPendingCount } from '@selectors/views';
export const getOverallCounter = (state: RootState): number => {
  const unreadMessagesCount = chatsGetUnreadMessagesCount(state) + (supportGetUnreadMessagesCount(state) ?? 0);
  const pendingInvitationsCount = invitationsGetPendingInvitationsCount(state);
  const viewsPendingCount = viewsGetPendingCount(state);
  return unreadMessagesCount + pendingInvitationsCount + viewsPendingCount;
};