import { AppFCC } from '@types';
import { HeaderButton } from '@kit/header/header-button';
import { HeaderContentStyled, HeaderLogoLinkStyled, HeaderLogoStyled, MoreIconStyled } from './styled';
import { HeaderContentMobileProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderContentMobile: AppFCC<HeaderContentMobileProps> = props => {
  const {
    logoLinkRouteDescriptor,
    headerControls,
    domain,
    onMoreClick
  } = props;
  return _jsxs(HeaderContentStyled, {
    children: [headerControls, _jsx(HeaderLogoLinkStyled, {
      routeDescriptor: logoLinkRouteDescriptor,
      children: _jsx(HeaderLogoStyled //
      , {
        src: "/images/logo-header.svg",
        alt: domain,
        loaderDisabled: true
      })
    }), _jsx(HeaderButton, {
      onClick: onMoreClick,
      children: _jsx(MoreIconStyled, {})
    })]
  });
};