import { appCreateSelector, RootState } from '@store';
import { SidebarState } from '@slices/sidebar';

/**
 * regular selectors
 */

export const sidebarGet = (state: RootState): SidebarState => {
  return state.ui.sidebar;
};

/**
 * memoized reselect selectors
 */

export const sidebarGetHidden: (state: RootState) => boolean = appCreateSelector([sidebarGet],
//
sidebar => sidebar.hidden);