import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { formatCounter } from '@helpers/format';
import { AppFC } from '@types';
import { getOverallCounter } from '@selectors/common';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { HeaderButton } from '@kit/header/header-button';
import { BurgerIconStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderButtonsWidget: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const openSidebarPopup = useCallback(() => {
    addPopup({
      id: POPUP.SIDEBAR
    });
  }, [addPopup]);
  const overallCounter = useTypedSelector(getOverallCounter);
  return _jsx(HeaderButton //
  , {
    badgeText: formatCounter(overallCounter),
    onClick: openSidebarPopup,
    children: _jsx(BurgerIconStyled, {})
  });
};