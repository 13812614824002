import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { formatCoins } from '@helpers/format';
import { isClient, isSpecialist } from '@helpers/member';
import { AppFC, AppFCC, CURRENCY_SYM } from '@types';
import { currentClientGetCoinAmount } from '@selectors/current-client';
import { currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { currentSpecialistGetBalanceAmount, currentSpecialistGetHasWallets } from '@selectors/current-specialist';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { PaymentsAddCardsAlertProps } from '@popups/modals/payments/payments-add-cards-alert';
import { PaymentsAddFundsModalProps } from '@popups/modals/payments/payments-add-funds';
import { PaymentsWithdrawFundsModalProps } from '@popups/modals/payments/payments-withdraw-funds';
import { HeaderWallet } from '@kit/header/header-wallet';
import { ArrowDownIconStyled, ArrowUpIconStyled } from './styled';
import { _HeaderWalletWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const _HeaderWalletWidget: AppFCC<_HeaderWalletWidgetProps> = props => {
  const {
    roleType,
    profileIsFilledAndApproved,
    amount,
    onWithdrawFundsClick,
    onAddFundsClick
  } = props;
  const _isClient = isClient(roleType);
  const _isSpecialist = isSpecialist(roleType);
  const currency = useMemo(() => {
    return _isClient ? formatCoins(amount) : _isSpecialist ? CURRENCY_SYM.RUB : null;
  }, [_isClient, _isSpecialist, amount]);
  const actionButtonProps = useMemo(() => {
    if (_isSpecialist) {
      return {
        icon: _jsx(ArrowUpIconStyled, {}),
        disabled: !profileIsFilledAndApproved || !amount,
        onClick: onWithdrawFundsClick
      };
    }
    if (_isClient) {
      return {
        icon: _jsx(ArrowDownIconStyled, {}),
        disabled: !profileIsFilledAndApproved,
        onClick: onAddFundsClick
      };
    }
  }, [_isClient, _isSpecialist, amount, profileIsFilledAndApproved, onAddFundsClick, onWithdrawFundsClick]);
  return _jsx(HeaderWallet, {
    amount: amount,
    currency: currency,
    actionButtonProps: actionButtonProps
  });
};
export const HeaderWalletWidget: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const profileIsFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const coinAmount = useTypedSelector(currentClientGetCoinAmount);
  const balanceAmount = useTypedSelector(currentSpecialistGetBalanceAmount);
  const hasWallets = useTypedSelector(currentSpecialistGetHasWallets);
  const _isSpecialist = isSpecialist(roleType);
  const amount = _isSpecialist //
  ? balanceAmount : coinAmount;
  const openPaymentsAddFundsModal = useCallback(() => {
    addPopup<PaymentsAddFundsModalProps>({
      id: POPUP.PAYMENTS_ADD_FUNDS
    });
  }, [addPopup]);
  const mayBeOpenPaymentsWithdrawFundsModal = useCallback(() => {
    if (!hasWallets) {
      addPopup<PaymentsAddCardsAlertProps>({
        id: POPUP.PAYMENTS_ADD_CARDS
      });
      return;
    }
    addPopup<PaymentsWithdrawFundsModalProps>({
      id: POPUP.PAYMENTS_WITHDRAW_FUNDS
    });
  }, [addPopup, hasWallets]);
  return _jsx(_HeaderWalletWidget //
  , {
    roleType: roleType,
    profileIsFilledAndApproved: profileIsFilledAndApproved,
    amount: amount,
    onWithdrawFundsClick: mayBeOpenPaymentsWithdrawFundsModal,
    onAddFundsClick: openPaymentsAddFundsModal
  });
};