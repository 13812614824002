import { AppFC } from '@types';
import { Header } from '@kit/header';
import { HeaderContentDesktopWidget } from './header-content/desktop';
import { HeaderContentMobileWidget } from './header-content/mobile';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderWidget: AppFC = () => {
  return _jsx(Header //
  , {
    headerContentMobile: _jsx(HeaderContentMobileWidget, {}),
    headerContentDesktop: _jsx(HeaderContentDesktopWidget, {})
  });
};