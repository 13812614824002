import { AppFCC } from '@types';
import { HeaderStyled } from './styled';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
type HeaderProps = {
  headerContentMobile: React.ReactChild;
  headerContentDesktop: React.ReactChild;
};
export const Header: AppFCC<HeaderProps> = props => {
  const {
    headerContentMobile,
    headerContentDesktop
  } = props;
  return _jsxs(HeaderStyled, {
    children: [headerContentMobile, headerContentDesktop]
  });
};