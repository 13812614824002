import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { getOriginDomain } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { usePopup } from '@hooks/popup';
import { HeaderButtonsWidget } from '@widgets/header/header-buttons';
import { HeaderContentMobile } from '@kit/header/header-content/mobile';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const HeaderContentMobileWidget: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const openMenuPopup = useCallback(() => addPopup({
    id: POPUP.HEADER_MENU
  }), [addPopup]);
  const logoLinkRouteDescritor = useMemo(() => ROUTES.search.index.getDescriptor(), []);
  return _jsx(HeaderContentMobile, {
    logoLinkRouteDescriptor: logoLinkRouteDescritor,
    headerControls: _jsx(HeaderButtonsWidget, {}),
    domain: getOriginDomain(),
    onMoreClick: openMenuPopup
  });
};